import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import ContentBlock from 'src/components/contentBlock';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "securefield"
    }}>{`SecureField`}</h1>
    <p>{`A masked `}<a parentName="p" {...{
        "href": "/form-inputs/textfield"
      }}><inlineCode parentName="a">{`TextField`}</inlineCode></a>{` for passwords or other sensitive data. `}</p>
    <ContentBlock imgName="" mdxType="ContentBlock">
  <code className="clike">
    {`
/* 
  struct SecureField<Label> where Label : View
*/
struct SecureField: View {
    @State private var password: String = ""\n
    var body: some View {
        Form {
            SecureField("Enter password", text: $password)\n
            SecureField("Enter password", text: $password)
                .textFieldStyle(RoundedBorderTextFieldStyle())
        }
    }
}
    `}
  </code>
    </ContentBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      